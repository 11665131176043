import React, {
  Suspense, lazy
  // , useState, useEffect, Fragment
} from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  // Link,
} from "react-router-dom";

// import Loadable from "react-loadable";

import AppLayout from "./AppLayout";

// // import NotFound from './NotFound';
import Page404 from "./Page404";
import ContentLoader from "../components/Loader/ContentLoader";


// const Investments = lazy(() => import("../containers/Investments/InvestmentMain"));
// const Questionnaire = lazy(() => import("../containers/Questionnaire/Questionnaire"));
const DreamsMain = lazy(() => import("../containers/Goals/DreamsMain"));
const PreLogin = lazy(() => import("../containers/PreLogin/PreLogin"));
const Login = lazy(() => import("../containers/Login/Login"));
const Home = lazy(() => import("../containers/DashBoard/Home"));
const SignUp = lazy(() => import("../containers/Login/SignUp"));
// const ForgotPassword = lazy(() => import("../containers/Login/ForgotPassword"));

const Routing = () => {

  return (
    <BrowserRouter>
      <Suspense
        fallback={
          <div class="text-center">
            <ContentLoader />
          </div>
        }
      ><AppLayout>
          <Routes>
            <Route path="/" element={<PreLogin />} />
            <Route path="/login" element={<Login />} />
            <Route path="/dashboard" element={<Home />} />
            <Route path="/Open-Account" element={<SignUp />} />
            {/* <Route path="/forgot-password" element={<ForgotPassword />} /> */}
            
            {/* <Route path="/Investments" element={<Investments />} />
          <Route path="/questionnaire" element={<Questionnaire />} /> */}
            <Route path="/404" component={Page404} />
            <Route path="/dreams/main/*" element={<DreamsMain />} />
          </Routes>
        </AppLayout>
      </Suspense>
    </BrowserRouter>
  );
};

export default Routing;
