import React from 'react';
import ProgressBar from 'react-bootstrap/ProgressBar';
import navIcon from './../../assets/images/navIcon.png'
import "./Header.scss";


function Header() {
  return (
    <>
      {/* <Navbar bg="dark" data-bs-theme="dark">
        <Container>
          <Navbar.Brand href="#home">Navbar</Navbar.Brand>
          <Nav className="me-auto">
            <Nav.Link href="#home">Home</Nav.Link>
            <Nav.Link href="#features">Features</Nav.Link>
            <Nav.Link href="#pricing">Pricing</Nav.Link>
          </Nav>
        </Container>
      </Navbar>
      <br />
      <Navbar bg="primary" data-bs-theme="dark">
        <Container>
          <Navbar.Brand href="#home">Navbar</Navbar.Brand>
          <Nav className="me-auto">
            <Nav.Link href="#home">Home</Nav.Link>
            <Nav.Link href="#features">Features</Nav.Link>
            <Nav.Link href="#pricing">Pricing</Nav.Link>
          </Nav>
        </Container>
      </Navbar>

      <br /> */}
      <div className=''>
        {/* <div className='bg_white py-5 d-flex justify-content-end'>
          <div className='nav_card'>
            <div className='nav_icon mr-4'>
              <img
                src={navIcon}
                alt="img"
                // className="img_fit"
              />
            </div>
            <div className='text-center txt_clr2 fs_30'>
              Plan Your Finance
            </div>


          </div>
        </div> */}

        <div className='bg_clr py-2'>


          <nav class="navbar navbar-expand-lg navbar-light">
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span class="navbar-toggler-icon"></span>
            </button>

            <div class="collapse navbar-collapse" id="navbarSupportedContent">
              <ul class="navbar-nav m-auto">
                <li class="nav-item mx-1">
                  <a class="nav-link" href="#">Home</a>
                </li>
                <li class="nav-item mx-1">
                  <a class="nav-link" href="#">About</a>
                </li>
                <li class="nav-item mx-1">
                  <a class="nav-link" href="#">Womantra</a>
                </li>
                <li class="nav-item mx-1">
                  <a class="nav-link fs_20" href="#">Financial Counselling</a>
                </li>
                <li class="nav-item mx-1">
                  <a class="nav-link" href="#">Knowledge Center</a>
                </li>
                <li class="nav-item mx-1">
                  <a class="nav-link" href="#">Media</a>
                </li>
                <li class="nav-item mx-1">
                  <a class="nav-link" href="#">Corporate Financial Wellness</a>
                </li>
                <li class="nav-item mx-1">
                  <a class="nav-link" href="#">Corporate Financial Wellness</a>
                </li>
                <li class="nav-item mx-1">
                  <a class="nav-link" href="#">Podcast & Videos</a>
                </li>


              </ul>

            </div>
          </nav>
        </div>

      </div>
      {/* <div class="">
      <ProgressBar>
      <ProgressBar striped variant="success" now={35} key={1} />
      <ProgressBar variant="warning" now={20} key={2} />
      <ProgressBar striped variant="danger" now={10} key={3} />
    </ProgressBar>
</div> */}
<div className='row justify-content-center'>

</div>
    </>
  );
}

export default Header;