import React, { Fragment } from "react";
// import Header from "../components/Header/Header";
// import NewHeader from "../components/Header/NewHeader";
import Header from "../components/Header/Header";

const AppLayout = (props) => {
  return (
    <Fragment>

      <Header />
      <div className="">{props.children}</div>
    </Fragment>
  );
};

export default AppLayout;
