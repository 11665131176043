// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navbar-light .navbar-nav .nav-link {
  color: white;
  font-size: 20px; }

.navbar-light .navbar-nav .active > .nav-link {
  color: black; }
`, "",{"version":3,"sources":["webpack://./src/components/Header/Header.scss"],"names":[],"mappings":"AAAA;EACI,YAAY;EACZ,eAAe,EAAA;;AAGnB;EACI,YAAY,EAAA","sourcesContent":[".navbar-light .navbar-nav .nav-link {\n    color: white;\n    font-size: 20px;\n}\n\n.navbar-light .navbar-nav .active>.nav-link {\n    color: black;\n}\n\n\n\n\n\n@media all and (max-width: 768px) {}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
