// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.spin_loader {
  display: inline-block;
  height: 50px;
  width: 50px;
  border-width: 3px;
  border-top-width: 3px;
  border-top-color: #36D7B7;
  border: solid rgba(255, 255, 255, 0.35);
  border-radius: 50%;
  border-top: solid;
  /* Safari */
  animation: spin 1s linear infinite; }

@keyframes spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.content_loader {
  display: inline-block;
  border-radius: 5px;
  height: 60px;
  width: 150px;
  background-image: linear-gradient(100deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0) 80%), linear-gradient(lightgray 12px, transparent 0), linear-gradient(lightgray 12px, transparent 0);
  background-size: 70px 150px, 80px 120px, 120px 120px;
  animation: shine 1s infinite linear;
  background-repeat: repeat-y;
  background-position: 0 0, 20px 20px, 0 40px; }

@keyframes shine {
  to {
    background-position: 100% 0, 20px 20px, 0px 40px; } }
`, "",{"version":3,"sources":["webpack://./src/components/Loader/Loader.scss"],"names":[],"mappings":"AACA;EACE,qBAAqB;EACrB,YAAY;EACZ,WAAW;EACX,iBAAiB;EACjB,qBAAqB;EACrB,yBAAyB;EACzB,uCAAoC;EACpC,kBAAkB;EAClB,iBAAiB;EAC2B,WAAA;EAC5C,kCAAkC,EAAA;;AAGpC;EACE;IAAK,uBAAuB,EAAA;EAC5B;IAAO,yBAAyB,EAAA,EAAA;;AAKlC;EACE,qBAAqB;EACrB,kBAAkB;EAClB,YAAY;EACZ,YAAY;EACZ,2NAOgD;EAChD,oDAAoE;EACpE,mCAAmC;EACnC,2BAA2B;EAE3B,2CAA2D,EAAA;;AAG7D;EACE;IACE,gDACU,EAAA,EAAA","sourcesContent":["// spin loader\n.spin_loader {\n  display: inline-block;\n  height: 50px;\n  width: 50px;\n  border-width: 3px;\n  border-top-width: 3px;\n  border-top-color: #36D7B7;\n  border: solid rgba(255,255,255,0.35);\n  border-radius: 50%;\n  border-top: solid;\n  -webkit-animation: spin 1s linear infinite; /* Safari */\n  animation: spin 1s linear infinite;\n}\n\n@keyframes spin {\n  0% { transform: rotate(0deg); }\n  100% { transform: rotate(360deg); }\n}\n\n\n// content loader\n.content_loader {\n  display: inline-block;\n  border-radius: 5px;\n  height: 60px;\n  width: 150px;\n  background-image: linear-gradient(\n      100deg,\n      rgba(255, 255, 255, 0),\n      rgba(255, 255, 255, 0.5) 50%,\n      rgba(255, 255, 255, 0) 80%\n    ),\n    linear-gradient(lightgray 12px, transparent 0),\n    linear-gradient(lightgray 12px, transparent 0);\n  background-size: 70px 150px, /* highlight */ 80px 120px, 120px 120px;\n  animation: shine 1s infinite linear;\n  background-repeat: repeat-y;\n\n  background-position: 0 0, /* highlight */ 20px 20px, 0 40px;\n}\n\n@keyframes shine {\n  to {\n    background-position: 100% 0, /* move highlight to right */ 20px 20px,\n      0px 40px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
